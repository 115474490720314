.nav-tabs {
  border-bottom: 2px solid var(--#{$variable-prefix}border-color);

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    border: var(--#{$variable-prefix}border-color);
    color: var(--#{$variable-prefix}800);
    font-weight: $font-weight-bold;
    font-size: $font-size-sm;
    @include border-top-radius($nav-tabs-border-radius);

    &:hover,
    &:focus {
      border-color: var(--#{$variable-prefix}white);
    }

    &.disabled {
      color: var(--#{$variable-prefix}black);
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: var(--#{$variable-prefix}primary);
    background-color: transparent;
    border-bottom: 2px solid var(--#{$variable-prefix}primary);
  }

}

.nav-pills-SG {
  background-color: var(--#{$variable-prefix}100);
  border-radius: .25rem !important;
  border: 1px solid var(--#{$variable-prefix}200);

  .btn {
    box-shadow: none;
    outline: 0;
    color: var(--#{$variable-prefix}600);

    &.active {
      background-color: var(--#{$variable-prefix}nav-pills-SG-active-bg-color) !important;
      box-shadow: var(--#{$variable-prefix}btn-SG-box-shadow) !important;
      color: var(--#{$variable-prefix}btn-SG-default-color);
    }

    &:active:focus {
      box-shadow: none;
    }

    @include hover-focus {
      color: var(--#{$variable-prefix}btn-SG-default-color);
    }
  }
}